import { Injectable } from "@angular/core";
import { CanActivate, CanDeactivate, ActivatedRouteSnapshot, Router } from "@angular/router";
import {  BaseService } from '../services';
import { first } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class AntiBackGuardService implements  CanDeactivate<any> {
  constructor(private _BaseService: BaseService,) {

  }
   canDeactivate(component: any) {
     if (component.changeNewContant) {
       return this.canDea(component);
     }
     return true;
   }
  canDea(component:any): Promise<any> | boolean {
       if (component.changeNewContant) {
         return new Promise((resolve, reject) => {
             this._BaseService.get('Message/1').pipe(first()).subscribe(result => {
         

        const data = result.data;

   });
         });
       } else {
         return true;
       }
     }
  }