import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { environment } from '../../../environments/environment';
import { BaseService } from '../services';
import { CryptoService } from '../services/crypto.service';

@Injectable()
export class AuthGuard implements CanActivate {
    lang = this.baseService.currentLang || localStorage.getItem('lang') || 'ar';

    constructor(private router: Router, private baseService: BaseService, private crypto: CryptoService,
        private auth: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        // if (!localStorage.getItem('userInfo')) {
        //     this.router.navigate([this.lang, environment.EntitiesFrontIdentity.Login]);
        //     return false;
        // }
        this.baseService.routingAfterLogin = state.url;

        let user = JSON.parse(localStorage.getItem('userInfo'));

       

        return false;


    }




}
