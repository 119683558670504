import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';


@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  key = "2266881010"
  constructor() { }

  decrypt(data: string) {
    // 1- from base64 to string 

    let d1 = CryptoJS.enc.Base64.parse(data);
    let dataString = CryptoJS.enc.Utf8.stringify(d1);
    const byteHash = CryptoJS.MD5(this.key)
    const decrypted = CryptoJS.TripleDES.decrypt(
      dataString,
      byteHash,
      {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }
    ).toString(CryptoJS.enc.Utf8);
     
    return decrypted;

  }

}
