import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { roles } from '../consts/roles';
import { CryptoService } from './crypto.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  permissionLevel = [];
  entities = [];
  permissionGuard = [];
  userPermission = [];
  userInfo= new BehaviorSubject(null)


  constructor(private crypto: CryptoService) {

  }

  getUserInfo(){
    let userInfo = localStorage.getItem('userInfo')
    return userInfo !=null ? JSON.parse(userInfo) : null
  }
  getPermissionLevel() {
    return JSON.parse(localStorage.getItem('userInfo')).permissionLevel
  }

  getEntities() {
    return JSON.parse(localStorage.getItem('userInfo')).entities
  }
  getRefernceId() {
    return JSON.parse(localStorage.getItem('userInfo')).referenceId
  }

  getReferenceMajorId() {
    return JSON.parse(localStorage.getItem('userInfo')).referenceMajorId
  }

  isSuperAdmin() {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo != null) {
      let roleId = this.crypto.decrypt(userInfo['roleId']);
      if (roleId == roles.Administrator)
        return true
    }
    return false;
  }

  getPermissionGuard() {
    this.userPermission = []
    this.permissionGuard = []

    this.permissionLevel = this.getPermissionLevel();
    this.entities = this.getEntities();

    this.permissions.forEach(entity => {
      if (this.entities.findIndex(item => item.id === entity.entityId) > -1) {
        entity.permissionLevel.forEach(level => {
          if (this.permissionLevel.findIndex(lvl => lvl.id === level.id) > -1) {
            if (level.route != "")
              this.permissionGuard.push(level.route)
            level.hasPermission = true;
          }
        })
        this.userPermission.push(entity)

      }
    })

    return this.permissionGuard;

  }


  getUserPermission() {
    this.userPermission = []
    this.permissionGuard = []

    this.permissionLevel = this.getPermissionLevel();
    this.entities = this.getEntities();

    this.permissions.forEach(entity => {
      if (this.entities.findIndex(item => item.id === entity.entityId) > -1) {
        entity.permissionLevel.forEach(level => {
          if (this.permissionLevel.findIndex(lvl => lvl.id === level.id) > -1) {
            if (level.route != "")
              this.permissionGuard.push(level.route)
            level.hasPermission = true;
          }
        })
        this.userPermission.push(entity)

      }
    })

    return this.userPermission;

  }

  getUserEntityPermission(entityId: string) {
    let _Permission = this.getUserPermission()

    let userPermission = new UserPermisionLevels()

    let ind = _Permission.findIndex(item => item.entityId === entityId);
    if (ind > -1) {
      userPermission.add = _Permission[ind].permissionLevel[0].hasPermission;
      userPermission.edit = _Permission[ind].permissionLevel[1].hasPermission;
      userPermission.delete = _Permission[ind].permissionLevel[2].hasPermission;
      userPermission.activate = _Permission[ind].permissionLevel[3].hasPermission;
      userPermission.list = _Permission[ind].permissionLevel[4].hasPermission;
      userPermission.view = _Permission[ind].permissionLevel[5].hasPermission;
      userPermission.Reports = _Permission[ind].permissionLevel[6].hasPermission;

    }
    return userPermission;
  }

  isNotHasPermission(routerLink) {
    let hasPer = false;
    this.permissionLevel = this.getPermissionLevel();
    this.entities = this.getEntities();

    this.permissions.forEach(entity => {
      entity.permissionLevel.forEach(level => {
        if (level.route === routerLink) {
          hasPer = true; return;
        }
      })
    })
    return hasPer;
  }

  Entities = environment.Entities;
  PermissionLevels = environment.PermissionLevels;



  permissions = [
    {
      entityId: this.Entities.careers,
      permissionLevel: [
        {
          id: this.PermissionLevels.Add,
          route: "/Careers/add",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Edit,
          route: "/Careers/edit",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Delete,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Activate,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.List,
          route: "/Careers/list",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.View,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Reports,
          route: "",
          hasPermission: false
        }
      ]

    },
    {
      permissionLevel: [
        {
          id: this.PermissionLevels.Add,
          route: "/JobAdvertisement/add",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Edit,
          route: "/JobAdvertisement/edit",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Delete,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Activate,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.List,
          route: "/JobAdvertisement/list",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.View,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Reports,
          route: "",
          hasPermission: false
        }
      ]

    },
    {
      entityId: this.Entities.News,
      permissionLevel: [
        {
          id: this.PermissionLevels.Add,
          route: "/news/add",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Edit,
          route: "/news/edit",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Delete,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Activate,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.List,
          route: "/news/list",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.View,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Reports,
          route: "",
          hasPermission: false
        }
      ]

    },
    {
      entityId: this.Entities.Articles,
      permissionLevel: [
        {
          id: this.PermissionLevels.Add,
          route: "/Articles/add",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Edit,
          route: "/Articles/edit",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Delete,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Activate,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.List,
          route: "/Articles/list",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.View,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Reports,
          route: "",
          hasPermission: false
        }
      ]

    },
    {
      entityId: this.Entities.Users,
      permissionLevel: [
        {
          id: this.PermissionLevels.Add,
          route: "/Users/add",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Edit,
          route: "/Users/edit",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Delete,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Activate,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.List,
          route: "/Users/list",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.View,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Reports,
          route: "",
          hasPermission: false
        }
      ]

    },
    {
      entityId: this.Entities.MainSlider,
      permissionLevel: [
        {
          id: this.PermissionLevels.Add,
          route: "/MainSlider/add",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Edit,
          route: "/MainSlider/edit",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Delete,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Activate,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.List,
          route: "/MainSlider/list",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.View,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Reports,
          route: "",
          hasPermission: false
        }
      ]

    },
    {
      entityId: this.Entities.Multimedia,
      permissionLevel: [
        {
          id: this.PermissionLevels.Add,
          route: "/Multimedia/images/add",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Edit,
          route: "/Multimedia/images/edit",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Delete,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Activate,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.List,
          route: "/Multimedia/images/list",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.View,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Reports,
          route: "",
          hasPermission: false
        }
      ]

    },
    {
      entityId: this.Entities.Videos,
      permissionLevel: [
        {
          id: this.PermissionLevels.Add,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Edit,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Delete,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Activate,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.List,
          route: "/Multimedia/videos/list",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.View,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Reports,
          route: "",
          hasPermission: false
        }
      ]

    },

    {
      entityId: this.Entities.Menus,
      permissionLevel: [
        {
          id: this.PermissionLevels.Add,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Edit,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Delete,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Activate,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.List,
          route: "/Menus/managment",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.View,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Reports,
          route: "",
          hasPermission: false
        }
      ]

    },
    {
      entityId: this.Entities.Departments,
      permissionLevel: [
        {
          id: this.PermissionLevels.Add,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Edit,
          route: "/Departments/edit",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Delete,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Activate,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.List,
          route: "/Departments/list",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.View,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Reports,
          route: "",
          hasPermission: false
        }
      ]

    },
    {
      entityId: this.Entities.Municipalities,
      permissionLevel: [
        {
          id: this.PermissionLevels.Add,
          route: "/Municipalities/add",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Edit,
          route: "/Municipalities/edit",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Delete,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Activate,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.List,
          route: "/Municipalities/list",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.View,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Reports,
          route: "",
          hasPermission: false
        }
      ]

    },
    {
      entityId: this.Entities.MobileApps,
      permissionLevel: [
        {
          id: this.PermissionLevels.Add,
          route: "/MobileApps/add",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Edit,
          route: "/MobileApps/edit",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Delete,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Activate,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.List,
          route: "/MobileApps/list",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.View,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Reports,
          route: "",
          hasPermission: false
        }
      ]

    },
    {
      entityId: this.Entities.Advertisements,
      permissionLevel: [
        {
          id: this.PermissionLevels.Add,
          route: "/Advertisements/add",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Edit,
          route: "/Advertisements/edit",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Delete,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Activate,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.List,
          route: "/Advertisements/list",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.View,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Reports,
          route: "",
          hasPermission: false
        }
      ]

    },
    {
      entityId: this.Entities.InitiativeProjects,
      permissionLevel: [
        {
          id: this.PermissionLevels.Add,
          route: "/InitiativeProjects/add",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Edit,
          route: "/InitiativeProjects/edit",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Delete,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Activate,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.List,
          route: "/InitiativeProjects/list",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.View,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Reports,
          route: "",
          hasPermission: false
        }
      ]

    },
    {
      entityId: this.Entities.ExternalSites,
      permissionLevel: [
        {
          id: this.PermissionLevels.Add,
          route: "/ExternalSites/add",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Edit,
          route: "/ExternalSites/edit",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Delete,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Activate,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.List,
          route: "/ExternalSites/list",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.View,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Reports,
          route: "",
          hasPermission: false
        }
      ]

    },
    {
      entityId: this.Entities.Officials,
      permissionLevel: [
        {
          id: this.PermissionLevels.Add,
          route: "/Officials/add",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Edit,
          route: "/Officials/edit",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Delete,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Activate,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.List,
          route: "/Officials/list",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.View,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Reports,
          route: "",
          hasPermission: false
        }
      ]

    },
    {
      entityId: this.Entities.Mayors,
      permissionLevel: [
        {
          id: this.PermissionLevels.Add,
          route: "/Mayors/add",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Edit,
          route: "/Mayors/edit",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Delete,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Activate,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.List,
          route: "/Mayors/list",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.View,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Reports,
          route: "",
          hasPermission: false
        }
      ]

    },
    {
      entityId: this.Entities.Princes,
      permissionLevel: [
        {
          id: this.PermissionLevels.Add,
          route: "/Princes/add",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Edit,
          route: "/Princes/edit",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Delete,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Activate,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.List,
          route: "/Princes/list",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.View,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Reports,
          route: "",
          hasPermission: false
        }
      ]

    },
    {
      entityId: this.Entities.Awards,
      permissionLevel: [
        {
          id: this.PermissionLevels.Add,
          route: "/Awards/add",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Edit,
          route: "/Awards/edit",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Delete,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Activate,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.List,
          route: "/Awards/list",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.View,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Reports,
          route: "",
          hasPermission: false
        }
      ]

    },
    {
      entityId: this.Entities.Partners,
      permissionLevel: [
        {
          id: this.PermissionLevels.Add,
          route: "/Partners/Partners/add",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Edit,
          route: "/Partners/Partners/edit",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Delete,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Activate,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.List,
          route: "/Partners/Partners/list",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.View,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Reports,
          route: "",
          hasPermission: false
        }
      ]

    },
    {
      entityId: this.Entities.LocalPartners,
      permissionLevel: [
        {
          id: this.PermissionLevels.Add,
          route: "/Partners/LocalPartners/add",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Edit,
          route: "/Partners/LocalPartners/edit",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Delete,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Activate,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.List,
          route: "/Partners/LocalPartners/list",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.View,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Reports,
          route: "",
          hasPermission: false
        }
      ]

    },
    {
      entityId: this.Entities.Documents,
      permissionLevel: [
        {
          id: this.PermissionLevels.Add,
          route: "/Documents/versions/add",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Edit,
          route: "/Documents/versions/edit",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Delete,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Activate,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.List,
          route: "/Documents/versions/list",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.View,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Reports,
          route: "",
          hasPermission: false
        }
      ]

    },
    {
      entityId: this.Entities.Versions,
      permissionLevel: [
        {
          id: this.PermissionLevels.Add,
          route: "/Documents/versions/add",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Edit,
          route: "/Documents/versions/edit",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Delete,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Activate,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.List,
          route: "/Documents/versions/list",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.View,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Reports,
          route: "",
          hasPermission: false
        }
      ]

    },
    {
      entityId: this.Entities.Eservices,
      permissionLevel: [
        {
          id: this.PermissionLevels.Add,
          route: "/Eservices/munServices/add",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Edit,
          route: "/Eservices/munServices/edit",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Delete,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Activate,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.List,
          route: "/Eservices/munServices/list",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.View,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Reports,
          route: "",
          hasPermission: false
        }
      ]

    },
    {
      entityId: this.Entities.GovServices,
      permissionLevel: [
        {
          id: this.PermissionLevels.Add,
          route: "/Eservices/govServices/add",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Edit,
          route: "/Eservices/govServices/edit",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Delete,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Activate,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.List,
          route: "/Eservices/govServices/list",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.View,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Reports,
          route: "",
          hasPermission: false
        }
      ]

    },
    {
      entityId: this.Entities.Regulations,
      permissionLevel: [
        {
          id: this.PermissionLevels.Add,
          route: "/Regulations/munsRegulations/add",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Edit,
          route: "/Regulations/munsRegulations/edit",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Delete,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Activate,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.List,
          route: "/Regulations/munsRegulations/list",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.View,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Reports,
          route: "",
          hasPermission: false
        }
      ]

    },
    {
      entityId: this.Entities.InvestmentsOpportunities,
      permissionLevel: [
        {
          id: this.PermissionLevels.Add,
          route: "/Investments/Opportunities/add",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Edit,
          route: "/Investments/Opportunities/edit",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Delete,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Activate,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.List,
          route: "/Investments/Opportunities/list",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.View,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Reports,
          route: "",
          hasPermission: false
        }
      ]

    },
    {
      entityId: this.Entities.InvestmentsCompetitions,
      permissionLevel: [
        {
          id: this.PermissionLevels.Add,
          route: "/Investments/Competitions/add",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Edit,
          route: "/Investments/Competitions/edit",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Delete,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Activate,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.List,
          route: "/Investments/Competitions/list",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.View,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Reports,
          route: "",
          hasPermission: false
        }
      ]

    },
    {
      entityId: this.Entities.ContactUs,
      permissionLevel: [
        {
          id: this.PermissionLevels.Add,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Edit,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Delete,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Activate,
          route: "",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.List,
          route: "/ContactUs/ContactUs/list",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.View,
          route: "/ContactUs/ContactUs/view",
          hasPermission: false
        },
        {
          id: this.PermissionLevels.Reports,
          route: "",
          hasPermission: false
        }
      ]

    },
   




  ]



}

export class UserPermisionLevels {
  constructor() {
    this.add = false
    this.edit = false
    this.delete = false
    this.activate = false
    this.list = false
    this.view = false
    this.Reports = false
  }
  add: boolean
  edit: boolean
  delete: boolean
  list: boolean
  view: boolean
  activate: boolean
  Reports: boolean
}